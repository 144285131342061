import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '@/views/admin/Admin'
import Galeria from '@/views/admin/Galeria'
import Login from '@/views/public/Login'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'admin',
    component: Admin,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Galeria,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next()
  }
})

export default router
