<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        Dashboard
      </v-col>
      <v-col cols="12" sm="6" md="6">
        --
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapActions } from 'vuex'


  export default {
    name: 'Admin',

  }
</script>
