<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="success"
                dark
                fab
                >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Nueva Foto
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                    <div v-if="foto!=''">
                        <v-img :src="foto"></v-img>
                    </div>
                    <ClipperImage
                        v-else
                        ref="clipper1"
                        key="clipper1"
                        @setPhoto="setFoto"
                        @disabledForm="desactivarForm(true)"
                        @cancel="desactivarForm(false)"
                    >
                    </ClipperImage>
                    <v-row class="pt-2">
                        <v-col cols="12">
                            <v-text-field
                                label="Descripcion"
                                autocomplete="off"
                                v-model="text"
                                :disabled="disabled"
                                :rules="requiredRule"
                                rounded
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Descripcion Eng"
                                autocomplete="off"
                                v-model="text_eng"
                                :disabled="disabled"
                                rounded
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                v-model="clase"
                                :items="clases"
                                label="Clase"
                                rounded
                                outlined
                                dense
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                label="Año"
                                autocomplete="off"
                                v-model="year"
                                :disabled="disabled"
                                :rules="requiredRule"
                                type="number"
                                rounded
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false">
                    Cerrar
                </v-btn>
                <v-btn
                    color="primary"
                    rounded
                    @click="newPicture"
                    :disabled="!valid"
                    :loading="loading"
                >
                    Crear
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ClipperImage from '@/components/ClipperImage'
import { mapActions } from 'vuex'

export default {
    name: 'NewPicture',
    components: {
        ClipperImage,
    },
    data () {
        return {
            dialog: false,
            lazy: false,
            valid: false,
            disabled: false,
            loading: false,
            text: '',
            text_eng: '',
            year: new Date().getFullYear(),
            foto: '',
            result: '',
            requiredRule: [(v) => !!v || "Este Campo es requerido"],
            clase: null,
            clases: ['', 'v-stretch', 'big-stretch', 'h-stretch']

        }
    },
    methods: {
        ...mapActions(['createPicture']),
        setFoto(picture, result){
            this.foto = picture
            this.result = result
            this.disabled = false
        },
        newPicture(){                        
            if (this.$refs.form.validate()) {
                if (this.result != "") {
                    this.loading=true
                    this.createPicture({
                        imagen: this.result,
                        text: this.text,
                        text_eng: this.text_eng,
                        year: this.year,
                        clase: this.clase
                    }).then(resp=>{
                        this.text=''
                        this.text_eng=''
                        this.result=''
                        this.foto=''
                        this.clase=''
                        this.year = new Date().getFullYear()
                        this.$emit('showMessage')
                        this.loading=false
                        this.dialog=false
                    })
                }
            }
        },
        desactivarForm(valor){
            this.disabled = valor
        }
    },

    created () {
        this.$root.$on('setYearFromTab', (year) =>{
            this.year = year
        })
    },

}
</script>

<style lang="scss" scoped>

</style>