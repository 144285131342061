import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import router from '../router'


Vue.use(Vuex)

//axios.defaults.baseURL = "http://localhost/api-cehm/public/api"
axios.defaults.baseURL = "https://centrohermanomanolo.net/services/public/api"


export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: localStorage.getItem("user") || {},
    pictures: []    
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
    authUser: (state) => state.user,
    pictures: (state)=> state.pictures
  },

  mutations: {
    logout(state) {
      state.status = ""
      state.token = ""
      state.user = {}
    },

    SET_TOKEN(state, token) {
      state.status = "success"
      state.token = token
    },

    SET_USER(state, user) {
      state.user = user
    },

    SET_AUTH_ERROR(state, error) {
      state.status = error
    },

    expires(state) {
      state.status = "token timeout"
    },

    SET_PICTURES  (state, payload) {
      state.pictures = payload
    },
    
    AUTH_SUCCESS(state, token) {
      state.status = "success"
      state.token = token
    },

    CREATE_PICTURE(state, payload){
      state.pictures.push(payload)
    },

    UPDATE_PICTURE(state, payload){
      state.pictures.map(pic=>{
        if(pic.id==payload.id){
          pic.url=payload.url
          pic.thumb=payload.thumb
          pic.text=payload.text
          pic.text_eng=payload.text_eng
          pic.year=payload.year
          pic.clase=payload.clase
        }
      })
    },

    UPDATE_PICTURE_NOFILE(state, payload){
      state.pictures.map(pic=>{
        if(pic.id==payload.id){
          pic.text=payload.text
          pic.text_eng=payload.text_eng
          pic.year=payload.year
          pic.clase=payload.clase
        }
      })
    },

    DELETE_PICTURE(state, payload){
      state.pictures = state.pictures.filter(pic=>pic.id!=payload)
    }

  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({ url: "/login", data: user, method: "POST" })
          .then((resp) => {
            const token = resp.data.token
            
              localStorage.setItem("token", token)
              axios.defaults.headers.common["Authorization"] = token
              commit("SET_TOKEN", token)
              resolve(resp)
          })
          .catch((err) => {
            commit("SET_AUTH_ERROR")
            localStorage.removeItem("token")
            reject(err)
          })
      })
    },    

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        delete axios.defaults.headers.common["Authorization"]
        resolve()
      })
    },

    refesh_token(context) {
      context.commit("expires")
      axios({ url: "/refresh", method: "POST" })
        .then((resp) => {
          const token = resp.data.token
          localStorage.setItem("token", token)
          axios.defaults.headers.common["Authorization"] = token
          context.commit("AUTH_SUCCESS", token)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    retrievePictures(context){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token
        axios({
          url: "/pictures",
          method: "GET"
        }).then((response)=>{
          context.commit("SET_PICTURES", response.data)
          resolve()
        }).catch(err=> {
          if(err.response.status==401){
            router.replace({ path: '/login' })
          }
        })        
      })
    },
  
    createPicture(context, data) {
      return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token

          const formData = new FormData()
          formData.append("imagen", data.imagen)
          formData.append("type", "jpg")
          formData.append("text", data.text)
          formData.append("text_eng", data.text_eng)
          formData.append("year", data.year)
          formData.append("clase", data.clase)

          axios({ 
              url: "/pictures", 
              data: formData, 
              method: "POST",
              headers: { "Content-Type": "multipart/form-data" }
            })
          .then((response) => {            
            context.commit("CREATE_PICTURE", {
              id: response.data.id,
              url: response.data.url,
              thumb: response.data.thumb,
              clase: data.clase,
              text: data.text,
              text_eng: data.text_eng,
              year: data.year,
              clase: data.clase
            })
            resolve('ok')
          })
          .catch((err) => {
            console.error('createPicture',err)
          })
      })
    },

    editPicture(context, data) {
      return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token

          const formData = new FormData()
          formData.append("imagen", data.imagen)
          formData.append("type", "jpg")
          formData.append("text", data.text)
          formData.append("text_eng", data.text_eng)
          formData.append("year", data.year)
          formData.append("clase", data.clase)

          axios({ 
              url: "/pictures/update-img/"+data.id,
              data: formData,
              method: "POST",
              headers: { "Content-Type": "multipart/form-data" }
            })
          .then((response) => {            
            context.commit("UPDATE_PICTURE", {
              id: data.id,
              url: response.data.url,
              thumb: response.data.thumb,
              text: data.text,
              text_eng: data.text_eng,
              year: data.year,
              clase: data.clase,
            })
            resolve('ok')
          })
          .catch((err) => {
            console.error('editPicture',err)
          })
      })
    },

    editPictureNoFile(context, data) {
      return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token

          axios({ 
              url: "/pictures/"+data.id,
              data: data,
              method: "PUT"
            })
          .then((response) => {            
            context.commit("UPDATE_PICTURE_NOFILE", {
              id: data.id,
              text: data.text,
              text_eng: data.text_eng,
              year: data.year,
              clase: data.clase
            })
            resolve('ok')
          })
          .catch((err) => {
            console.error('editPictureNoFile',err)
          })
      })
    },

    removePicture(context, id){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token

          axios({ 
              url: "/pictures/"+id,
              method: "DELETE"
            })
          .then((response) => {            
            context.commit("DELETE_PICTURE", id)
            resolve('ok')
          })
          .catch((err) => {
            console.error('removePicture',err)
          })
      })
    }

  },
  modules: {},
})
