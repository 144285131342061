<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-card>
      <v-layout column align-center>
        <img id="logo" src="@/assets/logo_min2.svg" height="310px" width="310px"/>
      </v-layout>
    </v-card>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item link to="/" class="tile">
        <v-list-item-icon>
          <v-icon class="skyblue--text">mdi-code-equal</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="skyblue--text subtitle-1">
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/gallery" class="tile">
        <v-list-item-icon>
          <v-icon class="skyblue--text">mdi-view-dashboard-edit</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="skyblue--text subtitle-1">
            Galeria
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideMenu",
  /* props: {
    drawer: Boolean,
  }, */
   data() {
       return {
           drawer: true
       }
   },

   created () {
       this.$root.$on("showHide", ()=>{
           this.drawer=!this.drawer
       })
   },

}
</script>

<style lang="scss" scoped>
    #logo{
        padding: 4em;
    }
</style>