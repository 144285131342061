<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          fab
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img
                :src="picture.thumb"
                :alt="picture.id"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ picture.year }}</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn x-small fab @click="confirm=true">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <section class="pa-3">
          <div class="caption ont-weight-thin font-italic">
            {{ picture.text.slice(0, 15) }}...
          </div>
          <div v-if="confirm" class="caption text-center">
            ¿Confirma que desea borrar?
          </div>
        </section>

        <v-card-actions v-if="confirm">
          <v-spacer></v-spacer>
          <v-btn
            x-small
            icon
            class="secondary"
            @click="menu=false;confirm=false"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn
            x-small
            text
            @click="deletePicture"
          >
          <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
</template>

<script>
import { mapActions } from 'vuex'

    export default {
        name: 'DeleteMenu',
        props: {
          picture: Object
        },
        data() {
          return {
            menu: false,
            confirm: false,
            loading: false
          }
        },
        methods: {
          ...mapActions(['removePicture']),
          deletePicture() {
            this.loading=true
            this.removePicture(this.picture.id).then(resp=>{
              this.loading=false
              this.menu=false
            })
          }
        },
        watch: {
          menu(newValue, oldValue) {
            if(!newValue){
              this.confirm=false
            }
          }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>