<template>
    <v-container>
        <v-row>
            <v-col v-for="picture in pictures" :key="picture.id" cols="12" md="4">
                <v-card>
                    <v-img
                        :src="picture.thumb"
                        aspect-ratio="1"
                    >
                        <DeleteMenu 
                            :picture="picture"
                        />
                        <v-btn x-small fab @click="$emit('opeDialog',picture)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-img>
                </v-card>
            </v-col>            
        </v-row>
    </v-container>
</template>
<script>
import DeleteMenu from '@/components/gallery/DeleteMenu'

    export default {
        name: 'PictureListPre',
        components: {
            DeleteMenu,
        },
        props: {
            pictures: Array
        },
    }
</script>

<style lang="scss" scoped>

</style>