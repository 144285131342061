<template>
    <v-container fluid>
        <v-card>
            <v-toolbar color="cyan" dark flat>
                <v-toolbar-title>Galeria de fotos {{ currentTabYear  }}</v-toolbar-title>
                <v-spacer></v-spacer>
            
                <v-btn icon>
                    <NewPicture 
                        @showMessage="verMensaje"
                    />
                </v-btn>
            </v-toolbar>
            <v-tabs
                v-model="tab"
                background-color="gray accent-4"
                centered
                dark
                icons-and-text
            >
            <v-tabs-slider></v-tabs-slider>
                <v-tab  v-for="year in years" :href="`#tab-${year}`" :key="year" @click="changeYear(year)">
                    {{ year }}
                </v-tab>
                
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="year in years" :key="year"
                    :value="'tab-' + year"
                >
                    <v-card flat>
                        <v-card-text>
                            <PictureListPre
                                :pictures="pictures.filter(elem=>elem.year===year)"
                                @opeDialog="showEditPicture"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="6000" bottom color="success">
        <p class="title white--text float-left">{{ mensaje }}</p>
        <v-btn
            small
            icon
            class="darkpage white--text float-right"
            fab
            @click="snackbar=false"
        >x</v-btn>
    </v-snackbar>
    <EditPicture/>
</v-container>
</template>

<script>
import NewPicture from '@/components/gallery/NewPicture'
import EditPicture from '@/components/gallery/EditPicture'
import PictureListPre from '@/components/gallery/PictureListPre'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Galeria',
        components: {
        NewPicture,
        EditPicture,
        PictureListPre
    },
    data() {
        return {
            years: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
            tab: 'tab-'+new Date().getFullYear(),
            snackbar: false,
            mensaje: '',
            currentTabYear: new Date().getFullYear()
        }
    },
    computed: {
        ...mapGetters(['pictures'])
    },

    methods: {
        ...mapActions(['retrievePictures']),
        reset() {            
        },
        verMensaje(){
            this.mensaje='Imagen creada con Exito'
            this.snackbar=true
        },
        showEditPicture(data){
            this.$root.$emit("setEditPicture", data)
        },
        changeYear(year){
            this.currentTabYear=year
            this.$root.$emit('setYearFromTab',this.currentTabYear)
        }
    },

    mounted () {
        this.retrievePictures()
        this.$root.$on("showSnackbar", () => {
            this.mensaje='Imagen actualizada con Exito'
            this.snackbar=true
        })
    },
}
</script>

<style lang="scss" scoped>

</style>