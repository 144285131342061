<template>    
<v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
>
    <v-card>
        <v-card-title>
            Editar Foto
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-img :src="url">
                    <ClipperEditImage
                        ref="clipper2"
                        key="clipper2"
                        @setPhoto="setFoto"
                        @disabledForm="desactivarForm(true)"
                        @cancel="desactivarForm(false)"
                        @hideOld="ocultarAnterior"
                    >
                    </ClipperEditImage>
                </v-img>
                <v-row class="pt-2">
                    <v-col cols="12">
                        <v-text-field
                            label="Descripcion"
                            autocomplete="off"
                            v-model="text"
                            :disabled="disabled"
                            :rules="requiredRule"
                            rounded
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Descripcion Eng"
                            autocomplete="off"
                            v-model="text_eng"
                            :disabled="disabled"
                            rounded
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-select
                            v-model="clase"
                            :items="clases"
                            label="Clase"
                            rounded
                            outlined
                            dense
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Año"
                            autocomplete="off"
                            v-model="year"
                            :disabled="disabled"
                            :rules="requiredRule"
                            type="number"
                            rounded
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
                            
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
                Cerrar
            </v-btn>
            <v-btn
                color="primary"
                rounded
                @click="updatePicture"
                :disabled="!valid"
                :loading="loading"
            >
                Actualizar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
    
</template>

<script>
import ClipperEditImage from '@/components/ClipperEditImage'
import { mapActions } from 'vuex'

export default {
    name: 'EditPicture',

    components: {
        ClipperEditImage,
    },
    data () {
        return {
            dialog: false,
            lazy: false,
            valid: false,
            disabled: false,
            loading: false,
            text: '',
            text_eng: '',
            year: '',
            url: '',
            urlCopy: '',
            result: '',
            requiredRule: [(v) => !!v || "Este Campo es requerido"],
            id: 0,
            clase: null,
            clases: ['', 'v-stretch', 'big-stretch', 'h-stretch']
        }
    },

    methods: {
        ...mapActions(['editPicture', 'editPictureNoFile']),
        setFoto(picture, result){
            this.url = picture
            this.result = result
            this.disabled = false
            this.$refs.clipper2.reset()
        },
        updatePicture(){
            if (this.$refs.form.validate()) {
                if (this.result != "") {
                    this.loading=true
                    this.editPicture({
                        id: this.id,
                        imagen: this.result,
                        text: this.text,
                        text_eng: this.text_eng,
                        year: this.year,
                        clase: this.clase
                    }).then(resp=>{
                        this.id=0
                        this.result=''
                        /* this.text=''
                        this.url=''
                        this.clase='' */
                        this.$root.$emit('showSnackbar')
                        this.loading=false
                        this.dialog=false
                    })
                }else{
                    this.loading=true
                    this.editPictureNoFile({
                        id: this.id,
                        text: this.text,
                        text_eng: this.text_eng,
                        year: this.year,
                        clase: this.clase
                    }).then(resp=>{
                        this.id=0
                        /* this.text=''
                        this.text_eng=''
                        this.result=''
                        this.url=''
                        this.clase='' */
                        this.$root.$emit('showSnackbar')
                        this.loading=false
                        this.dialog=false
                    })
                }
            }
        },
        desactivarForm(valor){
            this.disabled = valor
            this.url=this.urlCopy
        },
        close(){
            this.id=0
            /* this.url=''
            this.text=''
            this.year=0 */
            this.result=''
            this.dialog=false
        }, 
        ocultarAnterior(){
            this.url=''
        }
    },
    
    mounted () {
        this.$root.$on("setEditPicture", (data) => {
            this.id=data.id
            this.url=data.url
            this.urlCopy=data.url
            this.text=data.text
            this.text_eng=data.text_eng
            this.year=data.year
            this.clase=data.clase
            this.dialog=true
	    })
    },

}
</script>

<style lang="scss" scoped>

</style>