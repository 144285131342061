<template>
    <v-card class="px-2">
       <div class="text-center">
        <clipper-upload class="boton" v-model="photo" >
            <v-icon>mdi-image</v-icon>
        </clipper-upload>
       </div>
        <v-btn class="primary" small v-show="false" @click="getResult">
            <v-icon small>mdi-crop</v-icon>
        </v-btn>

        <v-row class="row px-1 py-1">
            <v-col cols="12">
            <clipper-basic :src="photo" ref="clipper">
                <div slot="placeholder">
                   Seleccione una imagen
                </div>
            </clipper-basic>
            </v-col>
            <v-col cols="12" v-show="false">
                <img v-if="result!=''" :src="result" alt="" />
            </v-col>
        </v-row>

       <v-card-actions>
           <v-btn
            text
            rounded
            small
            style="text-transform: none !important;"
            @click="cancelar">
            Cancelar
          </v-btn>
           <v-btn
            class="success"
            rounded
            small
            :disabled="hayfoto"
            style="text-transform: none !important;"
            @click="save"
            >
            Ok</v-btn>
       </v-card-actions>
    </v-card>
</template>

<script>
import Vue from "vue"
import VueRx from "vue-rx"
// Use build files
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd"
import "vuejs-clipper/dist/vuejs-clipper.css"
Vue.use(VueRx)
Vue.use(VuejsClipper)

export default {
  name: "ClipperEditImage",
  data() {
    return {
      pixel: "",
      hayfoto: true,
      loadingImage: false,
      result: "",
      photo: "",
      rotation: 0,
    }
  },

  methods: {
    upload(){
        this.$refs.upload.click
    },
    getResult() {
      const canvas = this.$refs.clipper.clip({ maxWPixel: 500 })
      this.pixel = `${canvas.width} x ${canvas.height}`
      this.result = canvas.toDataURL("image/jpeg")
    },
    clip() {
      this.getResult()
      const a = document.createElement("A")
      a.download = "result.jpg"
      a.href = this.result
      a.target = "_blank"
      a.click()
    },
    save() {
      const canvas = this.$refs.clipper.clip({ maxWPixel: 1000 })
      this.pixel = `${canvas.width} x ${canvas.height}`
      this.result = this.dataURItoBlob(canvas.toDataURL("image/jpeg"))
      this.$emit("setPhoto", URL.createObjectURL(this.result), this.result)
    },
    dataURItoBlob(dataURI) {      
      let byteString = atob(dataURI.split(",")[1])
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
      let ab = new ArrayBuffer(byteString.length)
      let ia = new Uint8Array(ab)      
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      let blob = new Blob([ab], { type: mimeString })
      return blob
    },
    cancelar(){
        this.result = ""
        this.photo = ""
        this.$emit("cancel")
    },
    reset(){
        this.result = ''
        this.photo = ''
    }
  },

  watch: {
    photo(newValue, oldValue) {
      if (newValue !== "") {
        this.hayfoto = false
        this.$emit('disabledForm')
        this.$emit('hideOld')
      } else {
        this.hayfoto = true
      }
    },
  },

};
</script>

<style scoped>
    .boton {
        background: #8080ff;
        border:none;
        color:#FFF;
        cursor:pointer;
    }
</style>
